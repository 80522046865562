import styled from "styled-components"
import breakpoints from "./breakpoints"


const StyledCartOverview = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--white);
    width:100%;
    height: 8rem;
    padding: 1.6rem;
    /* padding: 0 1.6rem 0; */

    @media screen and (min-width: ${breakpoints.sm}px) {
        background: none;
        display: grid;
        position: relative;
        height: 9rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        margin-top: 3rem;
    }
`

export {
    StyledCartOverview
}