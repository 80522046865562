import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";

const Section = styled.section`
  ${baseGridStyles}
  section {
    padding: 0;
  }
  p{
    margin: 0;
  }
`

const SectionBlock = styled(Section)`
  display: block;
  max-width: 130.4rem;
  margin: 0 auto;
  padding: calc((16 / 320) * 100%);
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: calc((24 / ${breakpoints.sm}px) * 100%);
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: calc((24 / ${breakpoints.md}px) * 100%);
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding: calc((32 / ${breakpoints.l}px) * 100%);
  }

  p{
    max-width: 86.2rem;
    margin: 0 auto;

  }
  h2{
    margin: 0 0 1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin: 0 0 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      margin: 0 0 3.2rem;
    }
  }
`

const SectionHeading = styled.div`
  text-align: left;
  margin:0;
  grid-column-start: 1;
  grid-column-end: 5;
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 2;
    grid-column-end: 6;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 3;
    grid-column-end: 11;
    text-align: center;
  }
`

const SectionWrapper = styled.div`
  max-width: 130.4rem;
  margin: 0 auto;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0 calc((24 / 720) * 100%) 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 calc((24 / 1024) * 100%) 0;
  }
  @media screen and (min-width: 1304px) {
    width: 100%;
    margin: 0 auto;
  }
`

const SectionLede = styled.p`
  text-align: left;
  margin-bottom: 1.6rem;
  grid-column: span 4;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 2;
    grid-column-end: 6;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 4;
    grid-column-end: 10;
    text-align: center;
  }
`

export { 
  Section, 
  SectionWrapper, 
  SectionHeading, 
  SectionLede, 
  SectionBlock
}
