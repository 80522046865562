import styled from "styled-components"
import breakpoints from "./breakpoints"
import { Link } from "gatsby"
import { semibold16 } from "./typography"

const StyledButton = styled.button`
  background: var(--light-blue-darkest);
  border: 0;
  border-radius: 100px;
  padding: 1.6rem;
  color: var(--grey-lightest);
  ${semibold16};
  line-height: 1.6rem;
  min-width: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.6rem auto 0 ;

  &:hover,
  &:focus {
    background: var(--light-blue-dark);
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.2;
    cursor: auto;
    background: var(--light-blue-darkest);
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 2.4rem auto 0 ${props => (props.align ? "0" : "auto")};
  }
`

const StyledLinkButton = styled(Link)`
  background: var(--light-blue-darkest);
  border: 0;
  border-radius: 100px;
  padding: 1.6rem;
  color: var(--grey-lightest);
  display: inline-block;
  min-width: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${semibold16};
  line-height: 1.6rem;
  margin: 1.6rem auto 0;
  text-decoration: none;
  white-space: nowrap;
  width: auto;
  &:visited {
    color: var(--grey-lightest);
  }
  &:hover {
    border-bottom: 0;
    cursor: pointer;
    color: var(--grey-lightest);
  }
  &:disabled {
    background: var(--green-light);
    cursor: auto;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    width: 15.8rem;
    margin: 1.6rem auto 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 2.4rem auto 0;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    width: 16.5rem;
  }
`

const StyledCloseModalButton = styled(StyledButton)`

  .index-modal &,
  .order-modal & {
    position: sticky;
    top: 0;
    left: calc(100% - 2.4rem);
    min-width: auto;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: block;
    margin-top: 0;

    @media screen and (min-width: ${breakpoints.md}px) {
      left: calc(100% - 3.2rem);
    }

    svg {
      position: absolute;
      left: 0.8rem;
      top: 1rem;
    }

    .topBun {
      fill: #fff;
      transform: rotate(45deg);
      transition: all 0.35s ease-in-out;
      transform-origin: 0.1rem 0.4rem;
    }

    .bottomBun {
      fill: #fff;
      transform: rotate(-45deg);
      transition: all 0.35s ease-in-out;
      transform-origin: 0 1rem;
    }
  }
`

const StyledAddToCartButton = styled(StyledButton)`
  margin-top: 0;
  /* background-color: var(--light-blue-dark); */
  
`
const StyledCheckoutButton  = styled(StyledButton)`
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    position: absolute;
    right: 0rem;
    bottom: 2.4rem;
  }
`

const StyledClearCartButton = styled(StyledButton)`
  position: absolute;
  bottom: 1.6rem;
  left: 1.6rem;
  background: var(--white);
  border: 0;
  padding: 1.6rem;
  color: var(--light-blue-darkest);

  &:hover,
  &:focus {
    background: var(--white);
    cursor: pointer;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    position: absolute;
    left: 0rem;
    bottom: 2.4rem;
    background: var(--grey-lightest);
  }
  @media screen and (min-width: ${breakpoints.sm}px) {
    background: var(--grey-lightest);
    padding-left: 0;

    &:hover,
    &:focus {
      background: var(--grey-lightest);
      cursor: pointer;
    }
  }
`
export { 
  StyledButton, 
  StyledLinkButton, 
  StyledCloseModalButton, 
  StyledAddToCartButton,
  StyledCheckoutButton,
  StyledClearCartButton
}
