import React, { useState } from 'react'
import { useShoppingCart } from 'use-shopping-cart'
import { StyledCheckoutButton, StyledClearCartButton } from "../../styledComponents/button"
import{ TotalCart } from "../../styledComponents/productList"
import{ StyledCartOverview } from "../../styledComponents/cart"

const CartOverview = () => {
  const [loading, setLoading] = useState(false)
  /* Gets the totalPrice and a method for redirecting to stripe */
  const {
    formattedTotalPrice,
    redirectToCheckout,
    cartCount,
    clearCart,
  } = useShoppingCart()
  
  return (
    <StyledCartOverview>
      {cartCount &&  
        <TotalCart cartCount={cartCount}>Total: {formattedTotalPrice}</TotalCart> 
        || ''} 
      <StyledCheckoutButton
        disabled={loading}
        onClick={() => {
          setLoading(true)
          redirectToCheckout()
        }}
      >
        {loading ? 'Loading...' : 'Checkout'}
      </StyledCheckoutButton>
      <StyledClearCartButton onClick={clearCart}>
        Clear cart
      </StyledClearCartButton>
    </StyledCartOverview>
  )
}

export default CartOverview
