import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SkusCoachingProgram from '../../components/Products/SkusCoachingProgram'
import CartOverview from '../../components/Cart/CartOverview'
import { 
  ProgramSection, 
  ProgramOverview } from '../../styledComponents/program'



const Program = ({ data }) => {
  return (
    <>
    <Layout>
      <SEO title={data.wpPage.title} />
      <ProgramSection id="overview">
        <ProgramOverview>
            <h2>Booking a personal consultation session</h2>
            <SkusCoachingProgram />
            <CartOverview />
        </ProgramOverview>
      </ProgramSection>     
    </Layout>
    </>
  )
}

export default Program

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "personal-consultation" }) {
      title
      content
      heroSection {
        kaarmibeingHeroBackground {
            uri
            localFile {
                childImageSharp {
                    sizes {
                        src
                    }
                }
            }
        }
        kaarmibeingHeroHeading
        kaarmibeingHeroLedeText
        kaarmibeingHeroPrimaryCta {
          title
          url
        }
      }
      OurProgram {
        programData {
          programContent
          programHeader
          programLede
          svg {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
