import React, { useState, useLayoutEffect } from 'react'
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import { ProductListItem, ProductPrice } from "../../styledComponents/productList"
import { StyledAddToCartButton } from "../../styledComponents/button"

const SkuCard = ({ sku }) => {

  const [isFullyBooked, setIsFullyBooked] = useState(false) // manage is success message state
  const { addItem } = useShoppingCart()
  const programPrice = formatCurrencyString({value: parseInt(sku.price),currency: sku.currency,})
  const fullPrice = sku.price
  const bookingstatus = sku.bookingstatus
  
  const checkIfAvailable = () => {

    if(bookingstatus === 'fullybooked') {
      console.log('xbookingstatus',bookingstatus)    
      setIsFullyBooked(true)
    }
  } 


  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfAvailable()
      return
    }
  }, [])
 
  return (
    <ProductListItem >
      <h4>{isFullyBooked && <span className="fully-booked">Fully booked </span>} {sku.name}</h4>
      {!isFullyBooked && sku.dates && <p className="event-dates">Then on the following: <strong>{sku.dates}</strong></p>}
      <p className="product-description">{sku.description}</p>
      <ProductPrice>
        <strong>{sku.discount && 'Earlybird '}Price: </strong>
        {formatCurrencyString({
          value: parseInt(sku.price),
          currency: sku.currency,
        })}
        {sku.discount &&  <span> save {sku.discount}%</span>}
      </ProductPrice>
      <StyledAddToCartButton 
        // disabled={() => checkIfAvailable()}
        disabled={isFullyBooked}
        onClick={() => addItem(sku)}
      >
        Add to cart
      </StyledAddToCartButton>
    </ProductListItem>
  )
}

export default SkuCard